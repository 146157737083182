<template>
    <v-app class="bk">
        <v-system-bar app dark window color="#3D1159">
            <v-spacer></v-spacer>

            <a href="https://www.lyndus.com.br/">Imprensa</a>
            <a href="https://www.lyndus.com.br">Trabalhe Conosco</a>
            <a class="last" href="https://www.lyndus.com.br">Fale Conosco</a>
        </v-system-bar>

        <v-container fill-height fluid>
            <v-row align="center" justify="center">
                <v-col xs="12" sm="12" md="12">
                    <v-card :elevation="24" class="mx-auto" max-width="400">
                        <v-form
                            v-model="recoverPassSuccessForm"
                            @submit.prevent="recoverySuccessSubmit()"
                            ref="recoverSuccessForm"
                        >
                            <v-card-text>
                                <div
                                    class="d-flex flex-column justify-space-between align-center"
                                >
                                    <v-img
                                        src="@/assets/logo.jpg"
                                        class="mt-2"
                                        max-height="150"
                                        max-width="300px"
                                    ></v-img>
                                </div>
                                <v-alert
                                    :value="true"
                                    type="warning"
                                    outlined
                                    v-if="errors"
                                    >{{ errors }}</v-alert
                                >
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="headline"
                                            >Seja Bem-Vindo
                                            (a)</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-text-field
                                        name="newPassword"
                                        type="password"
                                        label="Nova senha"
                                        v-model="newPassord"
                                        :rules="passwordRules"
                                        placeholder="Digite a nova senha"
                                        outlined
                                    ></v-text-field>
                                </v-list-item>
                                <v-list-item>
                                    <v-text-field
                                        name="passwordRecoveryCode"
                                        type="text"
                                        label="Código de recuperação"
                                        v-model="passwordRecoveryCode"
                                        placeholder="Informe seu código de recuperação."
                                        outlined
                                    ></v-text-field>
                                </v-list-item>

                                <div class="text-center w-100">
                                    <v-btn
                                        :to="{ name: 'login' }"
                                        text
                                        small
                                        color="indigo"
                                    >
                                        retornar ao login
                                    </v-btn>
                                </div>
                            </v-card-text>

                            <v-card-actions>
                                <v-btn block color="#6D59E8" dark large>
                                    <v-icon left dark>mdi-send</v-icon>
                                    Enviar
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-footer color="#3D1159" inset dark class="py-5 px-2 text-center">
            <v-spacer></v-spacer>
            <a
                href="https://goo.gl/maps/LBbJmfUr5atJteAVA"
                target="_blank"
                class="align-center"
            >
                Lyndus Tecnologia e Pagamentos Ltda, CNPJ 37.475.731/0001-01
                <br />Rua Padre Anchieta, 2348, CEP 80.730-001, Curitiba, PR
            </a>

            <v-spacer></v-spacer>
        </v-footer>
    </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";

// import store from "@/store";
// import { latLng } from "leaflet";

// import { LOGIN } from "@/store/actions/auth.type";
// import { CHANGE_PROGRESS } from "@/store/mutations/mutations.type";
import { validarCNPJ } from "@/common/cnpj.service";

export default {
    name: "RecoverySuccess",
    props: {
        source: String
    },

    data: () => ({
        recoverPassSuccessForm: false,

        isLoading: false,
        drawer: true,
        menu: 4,
        cnpjRules: [
            v => !!v || "O CNPJ é necessário",
            v => validarCNPJ(v) || "Informe um CNPJ válido."
        ],
        usernameRules: [
            v => !!v || "O seu codigo de usuário é necessário",
            v =>
                (v && v.length > 2) ||
                "Login deve conter no mínimo 3 caracteres!"
        ],

        passwordRules: [
            v => !!v || "Senha é necessária"
            // v =>
            //   /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            //   "Insura um e-mail válido"
        ]
    }),
    methods: {
        recoverySuccessSubmit(cnpj, username, password) {
            if (this.$refs.formLogin.validate()) {
                this.$store
                    .dispatch("LOGIN", {
                        // LOGIN não é uma string, só pus pra n dar erro!!!!
                        cnpj,
                        username,
                        password
                    })
                    .then(() => {
                        //(data) => {
                        this.$router.push({
                            name: "login"
                        });
                    });
            }
        },
        loginFailed() {
            this.error = "A recuperação de senha falhou!";
            // this.$store.dispatch("logout");
            delete localStorage.token;
            this.snackbar.snackbar = true;
            this.snackbar.color = "error";
            this.snackbar.text = "código inválido.";
        },
        reset() {
            this.$refs.recoverSuccessForm.reset();
        }
    },
    mounted() {},
    computed: {
        ...mapGetters(["inProgress", "snackbar"]),
        ...mapState({
            errors: state => state.auth.errors
        })
    }
};
</script>

<style scoped>
body {
    min-height: 600px;
}
.bk {
    background-image: url("~@/assets/background-bs.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}
.v-system-bar a {
    font-family: Arial, Helvetica, sans-serif;
    display: block;
    font-size: 10px;
    color: #ffffff;
    margin: 0px 0px 10px;
    text-decoration: none;
    line-height: 10px;

    float: left;
    display: block;
    height: 8px;
    margin-top: 13px;
    margin-right: 8px;
    padding-right: 8px;
    border-right: 1px solid #ffffff;
}

.v-system-bar .last {
    border-right: none;
}

.v-system-bar .on {
    color: #ffffff;
}

.v-footer a {
    font-family: "Montserrat";
    font-size: 12px;
    color: #ffffff;
    line-height: 15px;
    text-decoration: none;
}
</style>
